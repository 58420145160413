/* global angular */
(function () {
  'use strict';

  angular.module('EduWeb')
    .filter('removeHyphens', removeHyphens);

  function removeHyphens() {
    return function (input) {
      if (input) {
        return input.replace(/-/g, '');
      }
      return input;
    };
  }
}());
