/* global angular */
/* global _ */

(function () {
  'use strict';

  angular
    .module('EduWeb')
    .controller('AppController', appController);

  function appController(config, $routeParams) {
    const vm = this;

    vm.portalProvider = _.find(config.portalProviders,
      (portalProvider) => portalProvider.identifier === $routeParams.portalProviderIdentifier);

    vm.categoryEduwebIdentifier = $routeParams.categoryEduwebIdentifier;
    vm.foundCatalogItems = [];
  }
}());
