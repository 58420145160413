/* global angular */
(function () {
  'use strict';

  angular.module('EduWeb')
    .directive('appHeader', appHeader);

  function appHeader() {
    return {
      restrict: 'E',
      controllerAs: 'vm',
      scope: {},
      bindToController: {},
      controller: appHeaderController,
      templateUrl: '/scripts/components/appHeader.tpl.html',
    };

    function appHeaderController($log, dialogs, $translate, tmhDynamicLocale) {
      const vm = this;

      vm.logInModal = logInModal;
      vm.showLanguageSwitcher = showLanguageSwitcher;
      vm.switchLanguage = switchLanguage;

      function logInModal() {
        dialogs.create('/scripts/modals/logIn.tpl.html', 'LogInController as vm');
      }

      function showLanguageSwitcher(language) {
        if (language === $translate.use()) {
          return false;
        }
        return true;
      }

      function switchLanguage(language) {
        $translate.use(language);
        tmhDynamicLocale.set(language);
      }
    }
  }
}());
