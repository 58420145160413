/* global angular */
(function () {
  'use strict';

  angular
    .module('EduWeb', ['ngRoute', 'ngAnimate', 'ui.bootstrap', 'pascalprecht.translate',
      'ngSanitize', 'dialogs.main', 'angularMoment', 'tmh.dynamicLocale'])
    .config([
      '$locationProvider',
      '$routeProvider',
      '$translateProvider',
      'tmhDynamicLocaleProvider',
      function ($locationProvider, $routeProvider, $translateProvider, tmhDynamicLocaleProvider) {
        $locationProvider
          .html5Mode(true)
          .hashPrefix('!');

        // routes
        $routeProvider
          .when('/', {
            templateUrl: '/scripts/app/app.tpl.html',
            controller: 'AppController',
            controllerAs: 'vm',
          })
          .when('/:portalProviderIdentifier', {
            templateUrl: '/scripts/app/app.tpl.html',
            controller: 'AppController',
            controllerAs: 'vm',
          })
          .when('/:portalProviderIdentifier/:categoryEduwebIdentifier', {
            templateUrl: '/scripts/app/app.tpl.html',
            controller: 'AppController',
            controllerAs: 'vm',
          })
          .when('/:portalProviderIdentifier/catalogItem/:targetId', {
            templateUrl: '/scripts/app/catalogItem.tpl.html',
            controller: 'CatalogItemController',
            controllerAs: 'vm',
            caseInsensitiveMatch: true,
          })
          .otherwise({
            redirectTo: '',
          });

        $translateProvider.useSanitizeValueStrategy('escape');

        //
        $translateProvider.translations('en', {
          languageSwitchToEn: 'Engels',
          languageSwitchToNl: 'Nederlands',
          SignIn: 'Sign in',
          SearchHeader: 'What are you looking for?',
          Search: 'Search',
          SearchModulePlaceholder: 'Which training you want to follow?',
          moreInfo: 'More information',
          test: 'We hebben {{ value }} resultaten gevonden!',
          AdditionalInformation: 'Additional information',
          Provider: 'Provider',
          price: 'Price',
          priceInternal: 'Price internal',
          priceExternal: 'Price external',
          hour: 'hour',
          days: 'days',
          day: 'day',
          Duration: 'Duration',
          Accreditation: 'Accreditation',
          Validity: 'Validity',
          enroll: 'enroll',
          'welcomeToEduweb!': 'Welcome to Eduweb!',
          sideInfo: 'Eduweb is the online education catalogue of Erasmus MC. You can find content from the different education providers in Erasmus MC. If you want more information about the e-learning modules and training courses you can scroll through the catalogue on the right. If you want more information about how to contact us click on "More information?"',
          moreInfoIntro1: 'Employees of the Erasmus MC can access Eduplaza with their microsectionnumber.',
          moreInfoIntro2: 'Healthcare professionals who are not employed in Erasmus MC can also use Eduplaza after registration.',
          moreInfo1: 'All information regarding Eduplaza, e-learning modules and training courses can be found at',
          moreInfo2: 'All information regarding the Skillslab and SimulationCenter can be found at',
          NoUpcomingDates: 'There are no available dates in the future. Please contact {{ contactEmail }} for details.',
          Name: 'Name',
          Location: 'Location',
          Start: 'Start',
          End: 'End',
          ThereAreXPlacesAvailable: 'Available place(s): {{ available }}',
          UpcomingDates: 'Available dates',

        }).translations('nl', {
          languageSwitchToEn: 'English',
          languageSwitchToNl: 'Dutch',
          SignIn: 'Inloggen',
          SearchHeader: 'Waar ben je naar op zoek?',
          Search: 'Zoeken',
          SearchModulePlaceholder: 'Welke scholing wil je volgen?',
          moreInfo: 'meer info',
          test: 'We hebben {{ value }} resultaten gevonden!',
          AdditionalInformation: 'Aanvullende informatie',
          Provider: 'Aanbieder',
          price: 'Prijs',
          priceInternal: 'Prijs intern',
          priceExternal: 'Prijs extern',
          hour: 'uur',
          days: 'dagen',
          day: 'dag',
          Duration: 'Tijdsduur',
          Accreditation: 'Accreditatie',
          Validity: 'Geldigheid',
          enroll: 'inschrijven',
          'welcomeToEduweb!': 'Welkom op Eduweb!',
          sideInfo: 'Eduweb is de online onderwijscatalogus van het Erasmus MC. Ben je op zoek naar een bepaalde e-learning module of training, zoek dan in de catalogus rechts voor ons aanbod en de informatie over de scholingen. In het Erasmus MC bieden verschillende onderwijsaanbieders trainingen en e-learning modules aan. Wil je meer informatie over deze onderwijsaanbieders klik dan op de namen van de verschillende aanbieders hieronder. Heb je nog vragen? Klik dan op "Meer info?"',
          moreInfoIntro1: 'Medewerkers van het Erasmus MC hebben met hun microsectienummer toegang tot Eduplaza.',
          moreInfoIntro2: 'Zorgprofessionals die niet werkzaam zijn in het Erasmus MC kunnen ook gebruik maken van Eduplaza.',
          moreInfo1: 'Alle informatie over Eduplaza, de e-learning en het trainingsaanbod kun je vinden op',
          moreInfo2: 'Alle informatie over het Skillslab kun je vinden op',
          NoUpcomingDates: 'Er zijn geen beschikbare data in de toekomst. Neem contact op met {{ contactEmail }} om uw interesse aan te geven.',
          Name: 'Naam',
          Location: 'Locatie',
          Start: 'Begin',
          End: 'Einde',
          ThereAreXPlacesAvailable: 'Beschikbare plaats(en): {{ available }}',
          UpcomingDates: 'Komende data',
        });

        switch (navigator.language) {
          case 'nl':
            $translateProvider.preferredLanguage('nl');
            tmhDynamicLocaleProvider.defaultLocale('nl');
            break;
          case 'en':
            $translateProvider.preferredLanguage('en');
            tmhDynamicLocaleProvider.defaultLocale('en');
            break;
          default:
            $translateProvider.preferredLanguage('nl');
            tmhDynamicLocaleProvider.defaultLocale('nl');
        }

        tmhDynamicLocaleProvider.localeLocationPattern('/bower_components/angular-i18n/angular-locale_{{locale}}.js');
      },
    ])
    .value('config', {
      portalProviders: [{
        identifier: 'EDU',
        name: 'Eduplaza',
        url: 'https://catalogapi.sdbleerplatform.nl',
        portalUrl: 'https://erasmusmc.tcg-academy.nl',
        frontEndUrl: 'https://erasmusmc.sdbleerplatform.nl',
        contactEmail: 'eduplaza@erasmusmc.nl',
        portalIdentifier: 'ERASMUSMC',
      },
      {
        identifier: 'SKILLS',
        name: 'SkillsLab &  SimulationCenter',
        url: 'https://catalogapi.sdbleerplatform.nl',
        portalUrl: 'https://skillslab.tcg-academy.nl',
        frontEndUrl: 'https://skillslab.sdbleerplatform.nl',
        contactEmail: 'skillslab@erasmusmc.nl',
        portalIdentifier: 'SKILLSLAB',
      }],
    });
}());
