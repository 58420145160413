/* global angular */
(function () {
  'use strict';

  angular.module('EduWeb')
    .directive('searchCatalog', searchCatalog);

  function searchCatalog() {
    return {
      restrict: 'E',
      controllerAs: 'vm',
      scope: {},
      bindToController: {
        portalProvider: '=',
        foundCatalogItems: '=',
        searchText: '=',
      },
      controller: searchCatalogController,
      templateUrl: '/scripts/components/searchCatalog.tpl.html',
    };

    function searchCatalogController(catalogService) {
      const vm = this;

      vm.searchTextInternal = '';
      vm.searchCatalogItems = searchCatalogItems;

      function searchCatalogItems() {
        catalogService.searchItems(vm.searchTextInternal)
          .then((data) => {
            vm.foundCatalogItems = data.data;
            vm.searchText = vm.searchTextInternal;
          });
      }
    }
  }
}());
