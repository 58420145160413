/* global angular */
/* global _ */

(function () {
  'use strict';

  angular
    .module('EduWeb')
    .factory('catalogService', catalogService);

  function catalogService(config, moment, $http, $q) {
    return {
      searchItems,
      getCategory,
      getCategories,
      getAllItems,
      getItem,
      getTree,
      walkCategories,
    };

    function searchItems(searchText) {
      const operations = [];

      // Function overloading
      config.portalProviders
        .forEach((configPortalProvider) => searchItemsOperationsPush(configPortalProvider));

      function searchItemsOperationsPush(portalProvider) {
        operations.push(
          $http.get(`${portalProvider.url}/api/2.0/catalogItems/${portalProvider.portalIdentifier}/public?searchString=${searchText}`)
            .then((response) => response.data)
            .then((data) => {
              const tempData = data
                .filter((catalogItem) => {
                  // remove catalog items
                  if (!catalogItem.extraProperties) {
                    return true;
                  }

                  const excludeFromEduweb = catalogItem.extraProperties.find((x) => x.name === 'excludeFromEduweb');

                  if (excludeFromEduweb && excludeFromEduweb.value && excludeFromEduweb.value.toLowerCase() === 'true') {
                    return false;
                  }

                  return true;
                });

              tempData.forEach((catalogItem) => {
                catalogItem.categories.forEach((catalogItemCategory) => {
                  setCategoryEduwebIdentifier(catalogItemCategory);
                });

                // eslint-disable-next-line no-param-reassign
                catalogItem.portalProviderIdentifier = portalProvider.identifier;
              });
              return tempData;
            }),
        );
      }

      return $q.all(operations)
        .then((responses) => {
          let dataArr = [];
          responses.forEach((data) => {
            dataArr = dataArr.concat(data);
          });

          return {
            data: dataArr,
          };
        });
    }

    function getAllItems(portalProvider) {
      if (!portalProvider) {
        return $q.reject('no portalprovider');
      }

      return $http.get(`${portalProvider.url}/api/2.0/catalogItems/${portalProvider.portalIdentifier}/public`)
        .then((response) => response.data)
        .then((data) => {
          const tempData = data
            .filter((catalogItem) => {
              // remove catalog items
              if (!catalogItem.extraProperties) {
                return true;
              }

              const excludeFromEduweb = catalogItem.extraProperties.find((x) => x.name === 'excludeFromEduweb');

              if (excludeFromEduweb && excludeFromEduweb.value && excludeFromEduweb.value.toLowerCase() === 'true') {
                return false;
              }

              return true;
            });

          tempData.forEach((catalogItem) => {
            // eslint-disable-next-line no-param-reassign
            catalogItem.portalProviderIdentifier = portalProvider.identifier;
          });

          return tempData;
        });
    }

    function getItem(portalProvider, targetId) {
      return $q.all([
        $http.get(`${portalProvider.portalUrl}/api/1.0/catalogItem/${targetId}`),
        $http.get(`${portalProvider.url}/api/2.0/catalogItems/${portalProvider.portalIdentifier}/${targetId}/public`),
      ])
        .then(
          (responses) => {
            const item = responses[1].data;

            
            if (item.extraProperties && item.extraProperties.length) {
              const exclude = item.extraProperties.find((x) => x.name === 'excludeFromEduweb');
              if (exclude.value && exclude.value.toLowerCase() === 'true') {
                return null;
              }
            }

            item.categories.forEach((itemCategory) => {
              setCategoryEduwebIdentifier(itemCategory);
            });

            item.internalPrice = item.price || 0;
            item.externalPrice = item.price || 0;

            if (item.domainPriceProperties && item.domainPriceProperties.length) {
              const internalPriceProp = item.domainPriceProperties.find((x) => x.name === 'Price00f24123329e468b97e56bffda49c426');
              const externalPriceProp = item.domainPriceProperties.find((x) => x.name === 'Price2cbf728fde344490a25f40cb836090d6');

              if (internalPriceProp && internalPriceProp.value !== null) {
                item.internalPrice = internalPriceProp.value;
              }
              if (externalPriceProp && externalPriceProp.value !== null) {
                item.externalPrice = externalPriceProp.value;
              }
            }

            return {
              availablePlannings: responses[0].data.availablePlannings,
              catalogItem: item,
            };
          },
        );
    }

    function getCategory(portalProvider, categoryEduwebIdentifier) {
      const d = $q.defer();

      getCategories(portalProvider)
        .then((portalProviderCategories) => {
          let foundPortalProviderCategory = _.find(
            portalProviderCategories.data,
            // eslint-disable-next-line max-len
            (portalProviderCategory) => categoryEduwebIdentifier === portalProviderCategory.categoryEduwebIdentifier,
          );

          if (foundPortalProviderCategory === undefined
            || foundPortalProviderCategory === null) {
            portalProviderCategories.data.forEach((portalProviderCategory) => {
              foundPortalProviderCategory = findInSubCategories(portalProviderCategory);

              if (foundPortalProviderCategory !== undefined
                && foundPortalProviderCategory !== null) {
                d.resolve(foundPortalProviderCategory);
              }
            });
          } else {
            d.resolve(foundPortalProviderCategory);
          }
        });

      return d.promise;

      function findInSubCategories(portalProviderCategory) {
        let foundPortalProviderSubCategory = _.find(portalProviderCategory.subCategories,
          // eslint-disable-next-line max-len
          (portalProviderCategorySubCategory) => categoryEduwebIdentifier === portalProviderCategorySubCategory.categoryEduwebIdentifier);

        if (foundPortalProviderSubCategory) {
          return foundPortalProviderSubCategory;
        }

        portalProviderCategory.subCategories.forEach((subCategory) => {
          if (foundPortalProviderSubCategory !== undefined
            && foundPortalProviderSubCategory !== null) {
            return;
          }

          foundPortalProviderSubCategory = findInSubCategories(subCategory);
        });
        return foundPortalProviderSubCategory;
      }
    }

    function setCategoryEduwebIdentifier(category) {
      // eslint-disable-next-line no-param-reassign
      category.categoryEduwebIdentifier = category.name.replace(/([^a-zA-Z0-9]+)/g, '_');
      // eslint-disable-next-line no-param-reassign
      category.categoryEduwebIdentifier = category.categoryEduwebIdentifier.replace(/(^_(.*)_$)/, '$1');
      // eslint-disable-next-line no-param-reassign
      category.categoryEduwebIdentifier = category.categoryEduwebIdentifier.toLowerCase();
    }

    function getCategories(portalProvider) {
      if (!portalProvider) {
        return $q.reject('no portalprovider');
      }

      return $http.get(`${portalProvider.portalUrl}/api/1.0/catalogCategory`)
        .then((response) => response.data)
        .then((data) => {
          data.forEach(setCategoriesEduwebIdentifier);

          return data;
        });

      function setCategoriesEduwebIdentifier(category) {
        setCategoryEduwebIdentifier(category);
        category.subCategories.forEach(setCategoriesEduwebIdentifier);
      }
    }

    function getTree(portalProvider) {
      let { portalProviders } = config;

      function calculateTotal(items) {
        walkCategories(items, (category) => {
          // eslint-disable-next-line no-param-reassign
          category.totalCount = category.getTotal();
        });
      }

      if (portalProvider) {
        portalProviders = portalProviders
          .filter((x) => x.identifier === portalProvider.identifier);
      }

      const operations = portalProviders.map((provider) => getTreeInternal(provider));

      return $q.all(operations)
        .then((trees) => {
          let combined;

          trees.forEach((tree) => {
            if (!combined) {
              combined = tree;
            } else {
              combined = combineTrees(combined, tree);
            }
          });

          calculateTotal(combined);

          combined.forEach((x) => orderCatalogItems(x));

          return combined;
        });
    }

    function combineTrees(treeA, treeB) {
      treeA.forEach((categoryInA) => {
        // Copy catalogItems when category in B exists in category A
        // eslint-disable-next-line max-len
        treeB.filter((categoryInB) => categoryInA.categoryEduwebIdentifier === categoryInB.categoryEduwebIdentifier)
          .forEach((categoryInB) => {
            // copy catalogitems
            categoryInB.catalogItems.forEach((ci) => categoryInA.catalogItems
              .push(ci));

            // handle sub categories
            combineTrees(categoryInA.subCategories, categoryInB.subCategories);
          });
      });

      // Get categories in B not in A
      // eslint-disable-next-line max-len
      const toAdd = treeB.filter((categoryInB) => treeA.every((categoryInA) => categoryInA.categoryEduwebIdentifier !== categoryInB.categoryEduwebIdentifier));

      toAdd.forEach((i) => treeA.push(i));

      return treeA;
    }

    function orderCatalogItems(category) {
      // eslint-disable-next-line no-param-reassign
      category.catalogItems = _.sortBy(category.catalogItems, (i) => i.name);
      if (category.subCategories) {
        category.subCategories.forEach((x) => orderCatalogItems(x));
      }
    }

    function walkCategories(items, cb) {
      if (!items) {
        return;
      }

      items.forEach((item) => {
        cb(item);
        walkCategories(item.subCategories, cb);
      });
    }

    function getTreeInternal(portalProvider) {
      return $q.all([
        getAllItems(portalProvider),
        getCategories(portalProvider),
      ])
        .then((results) => {
          const catalogItems = results[0];
          const categories = results[1];

          walkCategories(categories, (category) => {
            // eslint-disable-next-line no-param-reassign
            category.getTotal = function () {
              const subCategorieTotal = category.subCategories
                .map((item) => item.getTotal())
                .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

              return category.catalogItems.length + subCategorieTotal;
            };

            // eslint-disable-next-line vars-on-top
            const catalogItemsInCategory = catalogItems.filter((i) => i.categories
              .some((c) => c.id === category.id));
            // eslint-disable-next-line no-param-reassign
            category.catalogItems = catalogItemsInCategory;
          });

          return categories;
        });
    }
  }
}());
