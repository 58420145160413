/* global angular */
(function () {
  'use strict';

  angular.module('EduWeb')
    .directive('catalogItemCategory', catalogItemCategory);

  function catalogItemCategory() {
    return {
      restrict: 'E',
      controllerAs: 'vm',
      bindToController: {
        portalProvider: '=',
        categoryEduwebIdentifier: '=',
      },
      scope: {},
      controller: catalogItemCategoryController,
      templateUrl: '/scripts/components/catalogItemCategory.tpl.html',
    };

    function catalogItemCategoryController(catalogService) {
      const vm = this;

      vm.catalogItemCategories = [];
      vm.foundCatalogItems = [];

      vm.collapseStates = [];

      catalogService.getTree(vm.portalProvider).then((tree) => {
        catalogService.walkCategories(tree, (item) => {
          // eslint-disable-next-line no-param-reassign
          item.collapsed = true;
        });
        setCollapsed(tree);
        vm.catalogItemCategories = tree;
      });

      function setCollapsed(categories) {
        let collapsed = true;

        categories.forEach((category) => {
          if (category.categoryEduwebIdentifier === vm.categoryEduwebIdentifier) {
            collapsed = false;
            // eslint-disable-next-line no-param-reassign
            category.collapsed = false;
          }

          if (collapsed !== false) {
            collapsed = setCollapsed(category.subCategories);
            // eslint-disable-next-line no-param-reassign
            category.collapsed = collapsed;
          }
        });

        return collapsed;
      }
    }
  }
}());
