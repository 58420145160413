/* global angular */
/* global _ */

(function () {
  'use strict';

  angular
    .module('EduWeb')
    .controller('CatalogItemController', catalogItemController);

  function catalogItemController(catalogService, config, $sce, $log,
    $routeParams, $rootScope, $timeout) {
    const vm = this;

    vm.catalogItem = {};
    vm.availablePlannings = [];

    vm.portalProvider = _.find(config.portalProviders,
      (portalProvider) => portalProvider.identifier === $routeParams.portalProviderIdentifier);

    catalogService.getItem(vm.portalProvider, $routeParams.targetId)
      .then((data) => {
        if(!data) {
          window.location.href = 'https://' + window.location.host;
        }

        vm.availablePlannings = data.availablePlannings;
        vm.catalogItem = data.catalogItem;
        if (!vm.catalogItem.price) {
          vm.catalogItem.price = 0;
        }
        vm.catalogItem.description = $sce.trustAsHtml(vm.catalogItem.description);

        vm.catalogItem.portalProviderIdentifier = vm.portalProvider.identifier;
        vm.catalogItem.portalFrontEndUrl = vm.portalProvider.frontEndUrl;
        vm.catalogItem.portalProviderName = vm.portalProvider.name;

        vm.catalogItem.imageUrl = vm.portalProvider.url + '/api/catalogImage/' + vm.portalProvider.portalIdentifier + '/' + vm.catalogItem.targetId + '/small';

        vm.catalogItem.studyLoadUnit = 'hour';

        const studyLoad = parseInt(vm.catalogItem.studyLoad, 10);

        if (Number.isInteger(studyLoad) && studyLoad % 8 === 0) {
          vm.catalogItem.studyLoadUnit = 'days';
          vm.catalogItem.studyLoad /= 8;
          if (vm.catalogItem.studyLoad === 1) {
            vm.catalogItem.studyLoadUnit = 'day';
          }
        }
        $timeout(() => {
          // eslint-disable-next-line no-param-reassign
          $rootScope.status = 'ready';
        }, 500);
      });
  }
}());
