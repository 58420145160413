/* global angular */
(function () {
  'use strict';

  angular.module('EduWeb')
    .directive('foundCatalogItems', foundCatalogItems);

  function foundCatalogItems() {
    return {
      restrict: 'E',
      controllerAs: 'vm',
      bindToController: {
        foundCatalogItems: '=',
      },
      scope: {},
      controller: foundCatalogItemsController,
      templateUrl: '/scripts/components/foundCatalogItems.tpl.html',
    };

    function foundCatalogItemsController($scope) {
      const vm = this;

      vm.translationData = {
        value: vm.foundCatalogItems.length,
      };

      $scope.$watch('vm.foundCatalogItems', () => {
        vm.translationData.value = vm.foundCatalogItems.length;
      });
    }
  }
}());
