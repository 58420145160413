/* global angular */
(function () {
  'use strict';

  angular
    .module('EduWeb')
    .controller('MoreInfoController', moreInfoController);

  function moreInfoController($modalInstance) {
    const vm = this;
    vm.cancel = cancel;

    function cancel() {
      $modalInstance.dismiss('cancel');
    }
  }
}());
