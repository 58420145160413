/* global angular */
(function () {
  'use strict';

  angular.module('EduWeb')
    .directive('catalogItemDetails', catalogItemDetails);

  function catalogItemDetails() {
    return {
      restrict: 'E',
      controllerAs: 'vm',
      bindToController: {
        availablePlannings: '=',
        catalogItem: '=',
        portalProvider: '=',
      },
      scope: {},
      controller: catalogItemDetailsController,
      templateUrl: '/scripts/components/catalogItemDetails.tpl.html',
    };

    function catalogItemDetailsController() {
    }
  }
}());
