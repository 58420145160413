/* global angular */
(function () {
  'use strict';

  angular.module('EduWeb')
    .directive('sideInfo', sideInfo);

  function sideInfo() {
    return {
      restrict: 'E',
      controllerAs: 'vm',
      scope: {},
      bindToController: {},
      controller: sideInfoController,
      templateUrl: '/scripts/components/sideInfo.tpl.html',
    };

    function sideInfoController(dialogs, config) {
      const vm = this;

      vm.portalProviders = config.portalProviders;
      vm.moreInfoModal = moreInfoModal;

      function moreInfoModal() {
        dialogs.create('/scripts/modals/moreInfo.tpl.html', 'MoreInfoController as vm');
      }
    }
  }
}());
